export const knowledgeTaskPageTypeVal = {
  PIC_TXT: 4,
  VIDEO: 3,
  CONVERSATION: 2,
  FORM: 1,
  // EXERCISES: 5
};

export const knowledgeTaskPageTypeValTitle = {
  4: '图文',
  3: '视频',
  2: '对话',
  1: '表单',
  // 5: '习题'
};

export const knowledgeTaskPageTypeValIconName = {
  4: 'ImageOutlined',
  3: 'OndemandVideoFilled',
  2: 'ChatBubbleOutlineFilled',
  1: 'FormatListBulletedFilled',
  5: 'NoteAltOutlined'
};